import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-variables',
  templateUrl: './icon-variables.component.html',
  styleUrls: ['./icon-variables.component.scss']
})
export class IconVariablesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
