import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'form-input-select3',
  templateUrl: './form-input-select3.component.html',
  styleUrls: ['./form-input-select3.component.scss']
})
export class FormInputSelect3Component implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() options: any[];
  @Input() className: string = "";
  @Input() selectedOption: any;
  @Input() disabledField: boolean = false;
  @Output() done = new EventEmitter();
  constructor() { }
  onSelect(event: TypeaheadMatch): void {
    if(event.item.id === '') this.selectedOption = '';
    this.control.setValue(event.item.id);
    this.done.emit(event.item);
  }
  ngOnInit() {
  }

}
