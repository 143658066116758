import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../../services';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  animations: [ 
    trigger('leftBlack', [
      state('off', style({
      })),
      state('on', style({
        borderRadius: '4px',
        width: '100%'
      })),
      transition('off => on', animate('1s 100ms cubic-bezier(0.86, -0.34, 0.12, 1.44)')),
      transition('on => off', animate('1s 100ms cubic-bezier(0.86, -0.34, 0.12, 1.44)'))
    ]),
    trigger('rightWhite', [
      state('off', style({
      })),
      state('on', style({
        width: '0',
        padding: '0',
        border: 'none'
      })),
      transition('off => on', animate('1s 100ms cubic-bezier(0.86, -0.34, 0.12, 1.44)')),
      transition('on => off', animate('1s 100ms cubic-bezier(0.86, -0.34, 0.12, 1.44)'))
    ])
  ],
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  public form: FormGroup;
  router: Router;
  sended = false;
  constructor(
    private formBuilder: FormBuilder,
    _router: Router,
    private _auth: AuthService
  ){
    this.form = formBuilder.group({
      email: ['', [ Validators.required ,Validators.email]]
    });
    this.router = _router;
    this.sended = false;
  }
  public onSubmit() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls)
      .forEach(controlName => controls[controlName].markAsTouched());
      // Breake run method 
      return;
    }

    console.log(this.form.value);
    this._auth.drop(this.form.value).subscribe(
      res => {
        this.sended = !this.sended;
      },
      err => {
        this.form.controls['email'].setErrors({'incorrect': true});
      }
    );
  }
  ngOnInit() {
    this.sended = false;
  }

}
