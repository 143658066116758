import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { AppSettingsService as _appSettings } from './../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'dashboard';

  get(): Observable<Dashboard> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<Dashboard>(uri, {headers: headers});
  }
}

interface Dashboard {
  totalActiveProducts: number,
  totalClosedPurchaseOrders: number,
  totalClosedSalesOrders: number,
  totalConfirmedSalesOrders: number,
  totalDraftPurchaseOrders: number,
  totalDraftSalesOrders: number,
  totalFulfilledSalesOrders: number,
  totalIssuedPurchaseOrders: number,
  totalProducts: number,
  totalSalesOrdersMonth: number,
  totalSalesOrdersYear: number,
  totalCustomers: number,
  "graphYear": {
    "JANUARY": number,
    "FEBRUARY": number,
    "MARCH": number,
    "APRIL": number,
    "MAY": number,
    "JUNE": number,
    "JULY": number,
    "AUGUST": number,
    "SEPTEMBER": number,
    "OCTOBER": number,
    "NOVEMBER": number,
    "DECEMBER": number
  }
}