import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  constructor(private _http: HttpClient, private _auth: AuthService) { }

  public model = 'settings';

  update(data): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<any>(uri, data, {headers: headers});
  }

  get(): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<any>(uri, {headers: headers});
  }

  getDefault(): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/default';
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<any>(uri, {headers: headers});
  }
}
