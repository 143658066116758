import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderItemsService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'purchaseOrderItems';

  create(data): Observable<PurchaseOrderItemPostPut> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<PurchaseOrderItemPostPut>(uri, data, {headers: headers});
  }

  update(data): Observable<PurchaseOrderItemPostPut> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<PurchaseOrderItemPostPut>(uri, data, {headers: headers});
  }

  list(data): Observable<PurchaseOrderItemGet> {
    //NEED PARAMETERS IN URL
    const { purchaseIds } = data;
    const params = purchaseIds ? '?purchaseIds=' + purchaseIds : ''
    const uri = _appSettings.API_ENDPOINT + this.model + params; // + to here
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<PurchaseOrderItemGet>(uri, {headers: headers});
  }
  
  get(id:number): Observable<PurchaseOrderItemGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<PurchaseOrderItemGetId>(uri, {headers: headers});
  }

  delete(id:number): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

}

interface PurchaseOrderItemGet {
  "content": PurchaseOrderItemGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface PurchaseOrderItemPostPut {
  "id"?: number,
  "name": boolean,
  "price": number,
  "productId": number,
  "productNameArchive": boolean,
  "purchaseOrderId": number,
  "quantity": string,
  "received"?: boolean,
  "tax": number
}

interface PurchaseOrderItemGetId {
  "id": number,
  "name": string,
  "price": number,
  "product": {
    "id": number,
    "name": string,
    picture: string
  },
  "productNameArchive": string,
  "purchaseOrder": {
    "id": number,
    "purchaseOrder": string
  },
  "quantity": number,
  "received": boolean,
  "receivedDate": string,
  "tax": number
}