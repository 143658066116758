import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'form-input-select',
  templateUrl: './form-input-select.component.html',
  styleUrls: ['./form-input-select.component.scss']
})
export class FormInputSelectComponent implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() options: any[];
  @Input() className: string = "";
  @Output() select = new EventEmitter();
  selectedOption: any;
  constructor() { }
  onSelect(event: TypeaheadMatch): void {
    this.control.setValue(event.item.id);
    this.select.emit(event.item.id)
    console.log(this.control)
  }
  ngOnInit() {
  }

}
