import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'form-select-canon',
  templateUrl: './form-select-canon.component.html',
  styleUrls: ['./form-select-canon.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('.1s', style({opacity: '1'}))
      ]),
      transition(':leave', [
        style({opacity: '1'}),
        animate('.1s', style({opacity: '0'}))
      ])
    ])
  ]
})
export class FormSelectCanonComponent implements OnInit {

  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() options: CustomOption[];
  @Input() className: string = "";
  @Output() done = new EventEmitter();
  @Input() selectedOption: CustomOption;
  @Input() noLabel: boolean = false;
  @Input() topList: boolean = false;
  isOpen: boolean = false;
  touched: boolean = false;

  constructor() { }

  isControlInvalid(control): boolean {
    const result = control.invalid && this.touched;
    return result;
  }

  onSelect(item): void {
    this.control.setValue(item.id);
    this.selectedOption = item;
    this.isOpen = false;
    this.done.emit(item);
  }

  getSelectedOption() {
    return !!this.selectedOption && this.selectedOption.name ? this.selectedOption.name : (this.noLabel ? '' : 'Select ') + this.label.toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {

    // this.ss = changes.selectedOption.currentValue;
    // console.log(changes.selectedOption.currentValue);
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values

  }

  ngOnInit() {
  }

}

interface CustomOption {
  id: number | string,
  name: string
}
