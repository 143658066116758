import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() options: object[];
  @Input() className: string = "";
  constructor() { }

  ngOnInit() {
  }

}
