import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { AppSettingsService as _appSettings } from './../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'upload';

  create(data): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/createFile';
    let formData: FormData = new FormData();
    formData.append('file', data, data.name);
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<any>(uri, formData, {headers: headers});
  }

  get(name: string): Observable<fileGet> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/downloadFile/' + name;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<fileGet>(uri, {headers: headers});
  }

  delete(name): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/deleteFile/' + name;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }
}

interface fileGet {
  "description": string,
  "file": {
    "absolute": true,
    "absolutePath": string,
    "canonicalPath": string,
    "directory": true,
    "file": true,
    "freeSpace": number,
    "hidden": true,
    "name": string,
    "parent": string,
    "path": string,
    "totalSpace": number,
    "usableSpace": number
  },
  "filename": string,
  "inputStream": any,
  "open": true,
  "readable": true,
  "uri": {
    "absolute": true,
    "authority": string,
    "fragment": string,
    "host": string,
    "opaque": true,
    "path": string,
    "port": number,
    "query": string,
    "rawAuthority": string,
    "rawFragment": string,
    "rawPath": string,
    "rawQuery": string,
    "rawSchemeSpecificPart": string,
    "rawUserInfo": string,
    "scheme": string,
    "schemeSpecificPart": string,
    "userInfo": string
  },
  "url": {
    "authority": string,
    "content": any,
    "defaultPort": number,
    "file": string,
    "host": string,
    "path": string,
    "port": number,
    "protocol": string,
    "query": string,
    "ref": string,
    "userInfo": string
  }
}