import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'purchaseOrders';

  create(data): Observable<number> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<number>(uri, data, {headers: headers});
  }

  update(data): Observable<PurchaseOrderPostPut> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<PurchaseOrderPostPut>(uri, data, {headers: headers});
  }

  list(data): Observable<PurchaseOrderGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const search = 'purchaseOrders=' + (data.search || '');
    const params = `?${size}&${page}&${search}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<PurchaseOrderGet>(uri, {headers: headers});
  }
  
  get(id): Observable<PurchaseOrderGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<PurchaseOrderGetId>(uri, {headers: headers});
  }

  delete(id): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

  status(data): Observable<any> {
    const id = data.id;
    const params = `?newStatus=${data.newStatus}`;
    const uri = _appSettings.API_ENDPOINT + this.model + '/change/status/' + id + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<any>(uri, data, {headers: headers});
  }

}

interface PurchaseOrderGet {
  "content": PurchaseOrderGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface PurchaseOrderPostPut {
  "address": string,
  "allProductsTotalQuantity": number,
  "allProductsTotalSalePrice": number,
  "city": string,
  "closedDate": string,
  "country": string,
  "facilityId": number,
  "facilityNameArchive": string,
  "id": number,
  "issuedDate": string,
  "orderDate": string,
  "postalCode": string,
  "province": string,
  "purchaseOrder": string,
  "salesOrder": string,
  "state": string,
  "status": string,
  "vendorId": number,
  "vendorNameArchive": string,
  totalCost: number,
  description: string
}

interface PurchaseOrderGetId {
  "address": string,
  "allProductsTotalQuantity": number,
  "allProductsTotalSalePrice": number,
  "city": string,
  "closedDate": string,
  "country": string,
  "facility": {
    "id": number,
    "name": string
  },
  "facilityNameArchive": string,
  "id": number,
  "issuedDate": string,
  "orderDate": string,
  "postalCode": string,
  "province": string,
  "purchaseOrder": string,
  "salesOrder": string,
  "state": string,
  "status": string,
  "vendor": {
    "id": number,
    "name": string
  },
  "vendorNameArchive": string,
  totalCost: number,
  description: string
}