import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'logs';

  list(data): Observable<LogsGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const dateFrom = 'dateFrom=' + (data.dateFrom || '');
    const dateTo = 'dateTo=' + (data.dateTo || '');
    const params = `?${size}&${page}&${dateFrom}&${dateTo}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<LogsGet>(uri, {headers: headers});
  }
  export(data, type: string) {
    // check and set params of pagination
    const dateFrom = 'dateFrom=' + (data.dateFrom || '');
    const dateTo = 'dateTo=' + (data.dateTo || '');
    const fileType = 'fileType=' + (type || '');
    const params = `?${dateFrom}&${dateTo}&${fileType}`;
    const uri = _appSettings.API_ENDPOINT + this.model + '/report' + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
      this._http.get(uri, {headers: headers, responseType: "arraybuffer" }).subscribe(
        res => {
          let exportType = type === 'PDF' ? 'pdf' : 'xlsx';
          var file = new Blob([res], { type: 'PDF' ? 'application/pdf' : 'application/octet-stream' })
          saveAs(file, 'report.'+exportType);
        },
        err => console.log('ERROR_25', err)
      );
  }

}

interface LogsGet {
  "content": LogGet[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface LogGet {
  "action": string,
  "authorityEmail": string,
  "authorityId": number,
  "authorityName": string,
  "authorityType": string,
  "createdAt": string,
  "id": number,
  "objectId": number,
  "objectType": string
}