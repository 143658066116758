import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderItemForProductItemService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'sales-order-item-for-product-items';

  create(data): Observable<SalesOrderItem4ProductItemGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<SalesOrderItem4ProductItemGetId>(uri, data, {headers: headers});
  }

  list(data): Observable<SalesOrderItem4ProductItemGetId[]> {
    //NEED PARAMETERS IN URL
    const { salesOrderItemId } = data;
    const params = salesOrderItemId ? '?salesOrderItemId=' + salesOrderItemId : ''
    const uri = _appSettings.API_ENDPOINT + this.model + params; // + to here
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesOrderItem4ProductItemGetId[]>(uri, {headers: headers});
  }

  delete(id:number): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }
  
}

interface SalesOrderItem4ProductItemGetId {
  id: number,
  sales_order_item_product_id: number,
  product_item_id: number
}