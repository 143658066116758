import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as IconsElements from './index';


@NgModule({
  imports: [ CommonModule ],
  declarations: [
    IconsElements.IconDashboardComponent,
    IconsElements.IconInventoryComponent,
    IconsElements.IconSalesComponent,
    IconsElements.IconSettingsComponent,
    IconsElements.IconSystemInfoComponent,
    IconsElements.IconUserComponent,
    IconsElements.IconVendorComponent,
    IconsElements.IconPurchaseOrderComponent,
    IconsElements.IconProductCategoryComponent,
    IconsElements.IconCustomerComponent,
    IconsElements.IconFacilityComponent,
    IconsElements.IconRoomComponent,
    IconsElements.IconTaxComponent,
    IconsElements.IconDiscountComponent,
    IconsElements.IconDeliveryComponent,
    IconsElements.IconInsuranceComponent,
    IconsElements.IconRoleComponent,
    IconsElements.IconLogsComponent,
    IconsElements.IconLocationsComponent,
    IconsElements.IconVariablesComponent
  ],
  providers: [],
  exports: [
    IconsElements.IconDashboardComponent,
    IconsElements.IconInventoryComponent,
    IconsElements.IconSalesComponent,
    IconsElements.IconSettingsComponent,
    IconsElements.IconSystemInfoComponent,
    IconsElements.IconUserComponent,
    IconsElements.IconVendorComponent,
    IconsElements.IconPurchaseOrderComponent,
    IconsElements.IconProductCategoryComponent,
    IconsElements.IconCustomerComponent,
    IconsElements.IconFacilityComponent,
    IconsElements.IconRoomComponent,
    IconsElements.IconTaxComponent,
    IconsElements.IconDiscountComponent,
    IconsElements.IconDeliveryComponent,
    IconsElements.IconInsuranceComponent,
    IconsElements.IconRoleComponent,
    IconsElements.IconLogsComponent,
    IconsElements.IconLocationsComponent,
    IconsElements.IconVariablesComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class IconsElementsModule { }