import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-logs',
  templateUrl: './icon-logs.component.html',
  styleUrls: ['./icon-logs.component.scss']
})
export class IconLogsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
