import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('.1s', style({opacity: '1'}))
      ]),
      transition(':leave', [
        style({opacity: '1'}),
        animate('.1s', style({opacity: '0'}))
      ])
    ])
  ]
})
export class FormDatepickerComponent implements OnInit {

  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() fullWidth: boolean;
  @Input() width: number = 200;
  @Input() password: boolean = false;
  @Input() requiredField: boolean = true;
  @Input() noLabel: boolean = false;
  @Input() disabledField: boolean = false;
  showPass = false;
  bsConfig = {
    adaptivePosition: true,
    dateInputFormat: 'YYYY-MM-DD',
    isAnimated: true
  }
  constructor() { }
  isControlInvalid(control): boolean {
    const result = control.invalid && control.touched;
    return result;
  }
  
  ngOnInit() {
  }

}
