import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'roles';

  create(data): Observable<RolesPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<RolesPostPutGetId>(uri, data, {headers: headers});
  }

  update(data): Observable<RolesPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<RolesPostPutGetId>(uri, data, {headers: headers});
  }

  list(data): Observable<RolesGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const params = `?${size}&${page}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<RolesGet>(uri, {headers: headers});
  }

  short(data): Observable<RolesShort[]> {
    //NEED PARAMETERS IN URL
    const uri = _appSettings.API_ENDPOINT + this.model + '/short'; // + to here
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<RolesShort[]>(uri, {headers: headers});
  }

  get(id): Observable<RolesPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<RolesPostPutGetId>(uri, {headers: headers});
  }

  delete(id): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

  archive(id): Observable<any> {
    // in swagger "QUERY ID" What is it?
    const uri = _appSettings.API_ENDPOINT + this.model + '/archive';
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

}

interface RolesGet {
  "content": RolesPostPutGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface RolesPostPutGetId {
  "accessParams": string[],
  "id"?: number,
  "name": string
}

interface RolesShort {
  "id": number,
  "name": string
}