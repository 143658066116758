import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination-select-size',
  templateUrl: './pagination-select-size.component.html',
  styleUrls: ['./pagination-select-size.component.scss']
})
export class PaginationSelectSizeComponent implements OnInit {
  options = [1,5,20,50];
  currentSize = 20;
  @Output() change = new EventEmitter();
  constructor() { }
  setSize(size): void {
    this.change.emit(size);
    this.currentSize = size;
  }
  ngOnInit() {
  }

}
