import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as FilterElements from './index';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    FilterElements.FilterInputComponent,
    FilterElements.FilterSelectComponent
  ],
  providers: [],
  exports: [
    FilterElements.FilterInputComponent,
    FilterElements.FilterSelectComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FiltersModule { }