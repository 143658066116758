import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms'

@Component({
  selector: 'form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss']
})
export class FormRadioComponent implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() valueCustom: string;
  id: number;
  constructor() {
    this.id = Math.round(Math.random()*100000000000000000);
  }

  ngOnInit() {
  }

}
