import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SalesTaxRatesService {

  constructor(private _http: HttpClient, private _auth: AuthService) { }

  public model = 'salesTaxRates';

  create(data): Observable<SalesTaxRatesPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<SalesTaxRatesPostPutGetId>(uri, data, {headers: headers});
  }

  update(data): Observable<SalesTaxRatesPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<SalesTaxRatesPostPutGetId>(uri, data, {headers: headers});
  }

  list(data): Observable<SalesTaxRatesGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const params = `?${size}&${page}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesTaxRatesGet>(uri, {headers: headers});
  }

  get(id): Observable<SalesTaxRatesPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesTaxRatesPostPutGetId>(uri, {headers: headers});
  }

  getByShipping(country, state): Observable<SalesTaxRatesPostPutGetId> {
    const c = 'country=' + (country || '');
    const s = 'state=' + (state || '');
    const params = `?${c}&${s}`;
    const uri = _appSettings.API_ENDPOINT + this.model + '/shipping' + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesTaxRatesPostPutGetId>(uri, {headers: headers});
  }

  delete(id): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

}

interface SalesTaxRatesGet {
  "content": SalesTaxRatesPostPutGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface SalesTaxRatesPostPutGetId {
  "id"?: number,
  "name": string,
  "rate": number,
  'shipping': boolean | number,
  'country': string,
  'state': string
}
