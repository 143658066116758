import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AppSettingsService {

	public static API_ENDPOINT = environment.baseUrl;

	public IMAGE_BASE = environment.uploadedBaseUrl;
	public ENUM_PROD_TYPE = [
		{ "type": "Sale", "rusName": "Продажа" },
		{ "type": "Rent", "rusName": "Аренда" },
		{ "type": "Service", "rusName": "Услуга" },
	];
	public ENUM_PROVINCES = [
		{ id: 'NOVA_SCOTIA', name: 'Nova scotia' },
		{ id: 'PRINCE_EDWARD_ISLAND', name: 'Prince edward island' },
		{ id: 'NEW_BRUNSWICK', name: 'New brunswick' },
		{ id: 'MANITOBA', name: 'Manitoba' },
		{ id: 'ONTARIO', name: 'Ontario' },
		{ id: 'NORTHWEST_TERRITORIES', name: 'Northwest territories' },
		{ id: 'YUKON', name: 'Yukon' },
		{ id: 'NEWFOUNDLAND_ADN_LABRADOR', name: 'Newfoundland adn labrador' },
		{ id: 'NUNAVUT', name: 'Nunavut' },
		{ id: 'SASKATCHEWAN', name: 'Saskatchewan' },
		{ id: 'BRITISH_COLUMBIA', name: 'British columbia' },
		{ id: 'QUEBEC', name: 'Quebec' },
		{ id: 'ALBERTA', name: 'Alberta' }
	];

	public ENUM_COUNTRIES = [
		{ id: 'USA', name: 'USA' },
		{ id: 'CANADA', name: 'Canada' }
	];

	public ENUM_STATES = [
		{ id: 'ALABAMA', name: "Alabama" },
		{ id: 'ALASKA', name: "Alaska" },
		{ id: 'ARIZONA', name: "Arizona" },
		{ id: 'ARKANSAS', name: "Arkansas" },
		{ id: 'CALIFORNIA', name: "California" },
		{ id: 'COLORADO', name: "Colorado" },
		{ id: 'CONNECTICUT', name: "Connecticut" },
		{ id: 'DELAWARE', name: "Delaware" },
		{ id: 'FLORIDA', name: "Florida" },
		{ id: 'GEORGIA', name: "Georgia" },
		{ id: 'HAWAII', name: "Hawaii" },
		{ id: 'IDAHO', name: "Idaho" },
		{ id: 'ILLINOIS', name: "Illinois" },
		{ id: 'INDIANA', name: "Indiana" },
		{ id: 'IOWA', name: "Iowa" },
		{ id: 'KANSAS', name: "Kansas" },
		{ id: 'KENTUCKY', name: "Kentucky" },
		{ id: 'LOUISIANA', name: "Louisiana" },
		{ id: 'MAINE', name: "Maine" },
		{ id: 'MARYLAND', name: "Maryland" },
		{ id: 'MASSACHUSETTS', name: "Massachusetts" },
		{ id: 'MICHIGAN', name: "Michigan" },
		{ id: 'MINNESOTA', name: "Minnesota" },
		{ id: 'MISSISSIPPI', name: "Mississippi" },
		{ id: 'MISSOURI', name: "Missouri" },
		{ id: 'MONTANA', name: "Montana" },
		{ id: 'NEBRASKA', name: "Nebraska" },
		{ id: 'NEVADA', name: "Nevada" },
		{ id: 'NEW_HAMPSHIRE', name: "New Hampshire" },
		{ id: 'NEW_JERSEY', name: "New Jersey" },
		{ id: 'NEW_MEXICO', name: "New Mexico" },
		{ id: 'NEW_YORK', name: "New York" },
		{ id: 'NORTH_CAROLINA', name: "North Carolina" },
		{ id: 'NORTH_DAKOTA', name: "North Dakota" },
		{ id: 'OHIO', name: "Ohio" },
		{ id: 'OKLAHOMA', name: "Oklahoma" },
		{ id: 'OREGON', name: "Oregon" },
		{ id: 'PENNSYLVANIA', name: "Pennsylvania" },
		{ id: 'RHODE_ISLAND', name: "Rhode Island" },
		{ id: 'SOUTH_CAROLINA', name: "South Carolina" },
		{ id: 'SOUTH_DAKOTA', name: "South Dakota" },
		{ id: 'TENNESSEE', name: "Tennessee" },
		{ id: 'TEXAS', name: "Texas" },
		{ id: 'UTAH', name: "Utah" },
		{ id: 'VERMONT', name: "Vermont" },
		{ id: 'VIRGINIA', name: "Virginia" },
		{ id: 'WASHINGTON', name: "Washington" },
		{ id: 'WEST_VIRGINIA', name: "West Virginia" },
		{ id: 'WISCONSIN', name: "Wisconsin" },
		{ id: 'WYOMING', name: "Wyoming" },
		// {id: 'WASHINGTON_D_C', name: "Washington D.C."}
	];

	public ENUM_ACCESS = {
		products: {
			view: 'PRODUCTS_VIEW',
			edit: 'PRODUCTS_EDIT',
			all: 'PRODUCTS_ALL'
		},
		vendors: {
			view: 'VENDORS_VIEW',
			edit: 'VENDORS_EDIT',
			all: 'VENDORS_ALL'
		},
		purchase_order: {
			view: 'PURCHASE_ORDER_VIEW',
			edit: 'PURCHASE_ORDER_EDIT',
			all: 'PURCHASE_ORDER_ALL'
		},
		product_categories: {
			view: 'PRODUCT_CATEGORIES_VIEW',
			edit: 'PRODUCT_CATEGORIES_EDIT',
			all: 'PRODUCT_CATEGORIES_ALL',
		},
		sales_orders: {
			view: 'SALES_ORDERS_VIEW',
			edit: 'SALES_ORDERS_EDIT',
			all: 'SALES_ORDERS_ALL'
		},
		customers: {
			view: 'CUSTOMERS_VIEW',
			edit: 'CUSTOMERS_EDIT',
			all: 'CUSTOMERS_ALL'
		},
		facilities: {
			view: 'FACILITIES_VIEW',
			edit: 'FACILITIES_EDIT',
			all: 'FACILITIES_ALL'
		},
		rooms: {
			view: 'ROOMS_VIEW',
			edit: 'ROOMS_EDIT',
			all: 'ROOMS_ALL'
		},
		sales_tax_rates: {
			view: 'SALES_TAX_RATES_VIEW',
			edit: 'SALES_TAX_RATES_EDIT',
			all: 'SALES_TAX_RATES_ALL'
		},
		discounts: {
			view: 'DISCOUNTS_VIEW',
			edit: 'DISCOUNTS_EDIT',
			all: 'DISCOUNTS_ALL'
		},
		delivery_and_pickup: {
			view: 'DELIVERY_AND_PICKUP_VIEW',
			edit: 'DELIVERY_AND_PICKUP_EDIT',
			all: 'DELIVERY_AND_PICKUP_ALL'
		},
		insurance: {
			view: 'INSURANCE_VIEW',
			edit: 'INSURANCE_EDIT',
			all: 'INSURANCE_ALL'
		},
		variables: {
			all: 'VARIABLES_ALL'
		},
		users: {
			view: 'USERS_VIEW',
			edit: 'USERS_EDIT',
			all: 'USERS_ALL'
		},
		roles: {
			view: 'ROLES_VIEW',
			edit: 'ROLES_EDIT',
			all: 'ROLES_ALL'
		},
		logs: {
			view: 'LOGS_VIEW'
		},
		company_info: {
			view: 'COMPANY_INFO_VIEW',
			edit: 'COMPANY_INFO_EDIT',
			all: 'COMPANY_INFO_ALL'
		}
	};

	public ENUM_UNITS_WEIGHT = [
		{ id: 'POUNDS', name: 'Pounds (lbs)' },
		{ id: 'OUNCES', name: 'Ounces (oz)' },
		{ id: 'KILOGRAMS', name: 'Kilograms (kg)' },
		{ id: 'GRAMS', name: 'Grams (g)' },
		{ id: 'MILLIGRAMS', name: 'Milligrams (mg)' }
	];

	public ENUM_UNITS_SIZE = [
		{ id: 'FEET', name: 'Feet (ft)' },
		{ id: 'INCHES', name: 'Inches (in)' },
		{ id: 'METERS', name: 'Meters (m)' },
		{ id: 'CENTIMETERS', name: 'Centimeters (cm)' },
		{ id: 'MILLIMETERS', name: 'Millimeters (mm)' }
	];

	public ENUM_EVERY_TIME = [
		{ id: 'День', name: 'День' },
		{ id: 'Неделя', name: 'Неделю' },
		{ id: 'раз в 2 недели', name: 'раз в 2 недели' },
		{ id: 'Месяц', name: 'Месяц' },
		{ id: 'Год', name: 'Год' }
	];

	public ENUM_EVERY_TIME_2 = [
		{ id: 'BI_WEEKLY', name: 'bi-weekly' },
		{ id: 'MONTHLY', name: 'Monthly' }
	];

	public ENUM_SALE_ORDER_STATUSES = [
		{ id: 'draft', name: 'Draft' },
		{ id: 'confirmed', name: 'Confirmed' },
		{ id: 'partially fulfilled', name: 'Partially Fulfilled' },
		{ id: 'fulfilled', name: 'Fulfilled' },
		{ id: 'in truck', name: 'In Truck' },
		{ id: 'delivered', name: 'Delivered' },
		{ id: 'in truck2', name: 'In Truck2' },
		{ id: 'returned', name: 'Returned' },
		{ id: 'closed', name: 'Closed' }
	];

	public ENUM_PURCHASE_ORDER_STATUSES = [
		{ id: 'DRAFT', name: 'Draft' },
		{ id: 'ISSUED', name: 'Issued' },
		{ id: 'CLOSED', name: 'Closed' }
	];

	public ENUM_TYPE_PRICE = [
		{ id: 'RENTAL', name: 'Rental' },
		{ id: 'SALE', name: 'Sale' }
	];

	public ENUM_TYPE_ORDER = [
		{ id: 'SALES_ORDER', name: 'Sales order' },
		{ id: 'CREDIT_NOTE', name: 'Credit note' }
	];

	public DEFAULT_PAGINATION: Pagination = {
		empty: true,
		first: true,
		last: true,
		number: 0,
		size: 20
	};

	constructor() { }
}

interface Pagination {
	empty: boolean,
	first: boolean,
	last: boolean,
	number: number,
	numberOfElements?: number,
	pageable?: any,
	size: number,
	sort?: { sorted: boolean, unsorted: boolean, empty: boolean },
	totalElements?: number,
	totalPages?: number,
	search?: string,
	dateFrom?: string,
	dateTo?: string
}