import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-select-icon',
  templateUrl: './form-select-icon.component.html',
  styleUrls: ['./form-select-icon.component.scss']
})
export class FormSelectIconComponent implements OnInit {
  @Input() control: AbstractControl;
  icons = new Array(166);
  constructor() { }
  setValue(value: number):void {
    this.control.setValue(value);
  }
  ngOnInit() {
  }

}
