import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule, TypeaheadModule, BsDropdownModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as FormElements from './index';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		TypeaheadModule.forRoot(),
		BsDropdownModule.forRoot()
	],
	declarations: [
		FormElements.FormInputComponent,
		FormElements.FormInputGroupComponent,
		FormElements.FormInputSelectComponent,
		FormElements.FormInputSelect2Component,
		FormElements.FormInputSelect3Component,
		FormElements.FormSelectComponent,
		FormElements.FormSelectIconComponent,
		FormElements.FormRadioComponent,
		FormElements.FormTextareaComponent,
		FormElements.FormCheckboxSlideComponent,
		FormElements.PaginationSelectComponent,
		FormElements.PaginationSelectSizeComponent,
		FormElements.FormSelectCanonComponent,
		FormElements.DragNDropInputFileDirective,
		FormElements.FormDatepickerComponent
	],
	providers: [],
	exports: [
		FormElements.FormInputComponent,
		FormElements.FormInputGroupComponent,
		FormElements.FormInputSelectComponent,
		FormElements.FormInputSelect2Component,
		FormElements.FormInputSelect3Component,
		FormElements.FormSelectComponent,
		FormElements.FormSelectIconComponent,
		FormElements.FormRadioComponent,
		FormElements.FormTextareaComponent,
		FormElements.FormCheckboxSlideComponent,
		FormElements.PaginationSelectComponent,
		FormElements.PaginationSelectSizeComponent,
		FormElements.FormSelectCanonComponent,
		FormElements.DragNDropInputFileDirective,
		FormElements.FormDatepickerComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FormElementsModule { }