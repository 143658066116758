import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragNDropInputFile]'
})
export class DragNDropInputFileDirective {

  @Output() onFileDropped = new EventEmitter<any>();
	
  // @HostBinding('style.background-color') private background = 'red'
  @HostBinding('style.border') public border = 'none';
  // @HostBinding('style.opacity') private opacity = '1'
	
  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = 'green';
    this.border = '3px dashed #4d71ec';
    // this.opacity = '0.8'
  }
	
  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = 'blue'
    this.border = 'none';
    // this.opacity = '1'
  }
	
  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = 'pink';
    this.border = 'none';
    // this.opacity = '1'
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }

}
