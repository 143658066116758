import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'form-input-text',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('.1s', style({opacity: '1'}))
      ]),
      transition(':leave', [
        style({opacity: '1'}),
        animate('.1s', style({opacity: '0'}))
      ])
    ])
  ]
})
export class FormInputComponent implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() fullWidth: boolean;
  @Input() width: number = 200;
  @Input() password: boolean = false;
  @Input() requiredField: boolean = true;
  @Input() noLabel: boolean = false;
  @Input() disabledField: boolean = false;
  showPass = false;
  constructor() { }
  isControlInvalid(control): boolean {
    const result = control.invalid && control.touched;
    return result;
  }
  ngOnInit() {
  }

}
