import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'rooms';

  create(data): Observable<RoomsPutPost> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<RoomsPutPost>(uri, data, {headers: headers});
  }

  update(data): Observable<RoomsPutPost> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<RoomsPutPost>(uri, data, {headers: headers});
  }

  list(data): Observable<RoomsGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const params = `?${size}&${page}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<RoomsGet>(uri, {headers: headers});
  }

  short(data): Observable<any> {
    // check and set params of pagination
    const facilityId = 'facilityId=' + (data.facilityId || '');
    const params = `?${facilityId}`;
    const uri = _appSettings.API_ENDPOINT + this.model + '/short' + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<any>(uri, {headers: headers});
  }

  get(id): Observable<RoomsGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<RoomsGetId>(uri, {headers: headers});
  }

  delete(id): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

}

interface RoomsGet {
  "content": RoomsGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface RoomsGetId {
  "facility": {
    "id"?: number,
    "_id"?: number,
    "name": string
  },
  "id": number,
  "name": string
}
interface RoomsPutPost {
  "facilityId": number,
  "id"?: number,
  "name": string
}
