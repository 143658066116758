import { Component, OnInit, Input, ElementRef, Renderer, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss']
})
export class FilterInputComponent implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() className: string = "";
  @Output() done = new EventEmitter();
  oldValue = "";
  newValue = "";
  timer;
  constructor(
    elementRef: ElementRef, 
    renderer: Renderer
  ) {
    renderer.setElementStyle(elementRef.nativeElement, 'position', 'relative');
  }
  test(newValue){
    clearTimeout(this.timer);
    this.timer = setTimeout(()=>{
      if(this.oldValue !== newValue) {
        this.oldValue = newValue;
        this.control.setValue(newValue);
        this.done.emit();
      }
    },2000);
  }
  ngOnInit() {
  }

}
