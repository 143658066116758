import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-discount',
  templateUrl: './icon-discount.component.html',
  styleUrls: ['./icon-discount.component.scss']
})
export class IconDiscountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
