import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderItemForProductService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'salesOrderItemForProducts';

  create(data): Observable<SalesOrderItemPostPut> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<SalesOrderItemPostPut>(uri, data, {headers: headers});
  }

  update(data): Observable<SalesOrderItemPostPut> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<SalesOrderItemPostPut>(uri, data, {headers: headers});
  }

  list(data): Observable<SalesOrderItemGet> {
    //NEED PARAMETERS IN URL
    const { salesOrderIds } = data;
    const params = salesOrderIds ? '?salesOrderIds=' + salesOrderIds : ''
    const uri = _appSettings.API_ENDPOINT + this.model + params; // + to here
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesOrderItemGet>(uri, {headers: headers});
  }

  // list4ProductItems(data): Observable<any> {
  //   //NEED PARAMETERS IN URL
  //   const { salesOrderIds } = data;
  //   const params = salesOrderIds ? '?salesOrderIds=' + salesOrderIds : ''
  //   const uri = _appSettings.API_ENDPOINT + this.model + '/product' + params; // + to here
  //   const headers = new HttpHeaders()
  //     .set('X-Auth-Token', this._auth.getToken())
  //     .set('accept', '*/*');
  //   return this._http.get<any>(uri, {headers: headers});
  // }
  
  get(id:number): Observable<SalesOrderItemGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesOrderItemGetId>(uri, {headers: headers});
  }

  delete(id:number): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

}

interface SalesOrderItemGet {
  "content": SalesOrderItemGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface SalesOrderItemPostPut {
  "id"?: number,
  "name": boolean,
  "price": number,
  "productId": number,
  "quantity": string,
  "tax": number,
  "category": string,
  "description": string,
  "discount": boolean,
  "picture": string,
  "productArchiveName": string,
  "productArchiveNumber": string,
  "salesOrderId": number,
}

interface SalesOrderItemGetId {
  "category": string,
  "description": string,
  "discount": boolean,
  "id": number,
  "pictures": string[],
  "price": number,
  "product": {
    "id": number,
    "name": string,
    "pictures": string[],
    "wp_id": number
  },
  "productArchiveName": string,
  "productArchiveNumber": string,
  "quantity": number,
  "salesOrder": {
    "billAddress": string,
    "billCity": string,
    "billCountry": string,
    "billPostalCode": string,
    "billProvince": string,
    "billState": string,
    "closedDate": string,
    "comment": string,
    "confirmedDate": string,
    "customer": {
      "firstName": string,
      "id": number,
      "lastName": string,
      "phone": string,
      "username": string
    },
    "customerArchive": string,
    "deliveredDate": string,
    "deliveryCost": number,
    "discount": number,
    "expReturnDate": string,
    "fullfieldDate": string,
    "id": number,
    "insurance": number,
    "orderDate": string,
    "period": string,
    "phone": string,
    "salesOrder": string,
    "shipAddress": string,
    "shipCity": string,
    "shipCountry": string,
    "shipPostalCode": string,
    "shipProvince": string,
    "shipState": string,
    "status": string,
    "tax": number,
    "totalPrice": number,
    "totalQuantity": number,
    "type": string,
    "typePrice": string
  },
  "tax": number
}