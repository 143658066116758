import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'form-input-select2',
  templateUrl: './form-input-select2.component.html',
  styleUrls: ['./form-input-select2.component.scss']
})
export class FormInputSelect2Component implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() options: any[];
  @Input() className: string = "";
  @Output() done = new EventEmitter();
  @Input() selectedOption: any;
  constructor( ) { }
  onSelect(event: TypeaheadMatch): void {
    this.control.setValue(event.item.id);
    this.done.emit(event.item);
  }
  clear() {
    event.stopPropagation();
    this.selectedOption = '';
    this.control.setValue('');
    this.done.emit('');
  }
  ngOnInit() {
  }

}