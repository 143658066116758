import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ToastrModule } from 'ngx-toastr';
//Routings
import { AppRoutingModule } from './app-routing.module';
//Modules
import { FormElementsModule } from './common/form/formElements.module';
import { FiltersModule } from './common/filter/filters.module';
import { IconsElementsModule } from './common/icons/iconsElements.module';
// import { SettingsModule } from './pages/modules/settings/settings.module';
// import { UsersModule } from './pages/modules/users/users.module';
// import { SalesModule } from './pages/modules/sales/sales.module';
// import { InventoryModule } from './pages/modules/inventory/inventory.module';
// import { DashboardModule } from './pages/modules/dashboard/dashboard.module';
//Components
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { PageContainerComponent } from './pages/page-container/page-container.component';
import { MainNavComponent } from './common/main-nav/main-nav.component';
import { RegistrationConfirmComponent } from './pages/auth/registration-confirm/registration-confirm.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import * as Dialogs from './common/dialog';
//Services
import * as Services from './services';
import { Globals } from './services/globals';
import { ObjectToArrayPipe } from './services/objectToArray/objectToArray.pipe';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		PageContainerComponent,
		MainNavComponent,
		Dialogs.DialogDeleteComponent,
		RegistrationConfirmComponent,
		ForgotComponent,
		ObjectToArrayPipe
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormElementsModule,
		FiltersModule,
		IconsElementsModule,
		MatDialogModule,
		MatSlideToggleModule,
		// SettingsModule,
		// UsersModule,
		// SalesModule,
		// InventoryModule,
		// DashboardModule
		LeafletModule.forRoot(),
		ToastrModule.forRoot()
	],
	providers: [
		// Settings
		Services.AuthService,
		Services.AuthGuardService,
		Services.AppSettingsService,
		Services.RoomsService,
		Services.SalesTaxRatesService,
		Services.DiscountsService,
		Services.DeliveryAndPickUpService,
		Services.InsuranceService,
		Services.FacilitiesService,
		// Users
		Services.UsersService,
		Services.RolesService,
		Globals
	],
	bootstrap: [AppComponent],
	entryComponents: [
		Dialogs.DialogDeleteComponent
	]
})
export class AppModule { }
