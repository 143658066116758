import { Component, OnInit, ElementRef, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from './../../services/globals';

@Component({
	selector: 'main-nav',
	templateUrl: './main-nav.component.html',
	styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {

	userRoles = [];
	height: number;
	hoverExit = 'png';
	userName: string = null;
	private globals;
	generalSettings = [
		'DELIVERY_AND_PICKUP_',
		'DISCOUNTS_',
		'FACILITIES_',
		'INSURANCE_',
		'ROOMS_',
		'SALES_TAX_RATES_'];
	constructor(
		elementRef: ElementRef,
		renderer: Renderer,
		private route: Router,
		private _globals: Globals
	) {
		this.userRoles = JSON.parse(localStorage.getItem('roles'));
		renderer.setElementStyle(elementRef.nativeElement, 'z-index', '111111111');
		// renderer.setElementStyle(elementRef.nativeElement, 'width', '72px');
		this.globals = this._globals;

	}

	setHeight() {
		let h = () => {
			let y = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
			this.height = y - (150);
		}
		h();
		window.addEventListener('resize', h);
	}

	clickMenu = () => {
		this._globals.menuState = !this.globals.menuState;
	}
	getMenu = () => this.globals.menuState ? 256 : 72;

	logout() {
		this.route.navigateByUrl('/login');
	}

	getUserInfo() {
		let user = JSON.parse(localStorage.getItem("user"));
		this.userName = user || 'user';
	}

	checkAccess = (access) => {
		return this.userRoles.some(r => access.some(a => r.includes(a)));
		//console.log('wadwad' + access);
		//const result = Array.isArray(access) ? access.find(num => num % 2 === 0) : 0;

		//console.log(result); // 👉️ 
		//TODO
		//return true;
	}

	ngOnInit() {
		this.setHeight();
		this.getUserInfo();
	}

}
