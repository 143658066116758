import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';


@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'vendors';

  create(data): Observable<VendorsPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<VendorsPostPutGetId>(uri, data, {headers: headers});
  }

  update(data): Observable<VendorsPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<VendorsPostPutGetId>(uri, data, {headers: headers});
  }

  list(data): Observable<VendorsGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const search = 'search=' + (data.search || '');
    const params = `?${size}&${page}&${search}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<VendorsGet>(uri, {headers: headers});
  }

  shorts(data): Observable<Short[]> {
    //NEED PARAMETERS IN URL
    const uri = _appSettings.API_ENDPOINT + this.model + '/short'; // + to here
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<Short[]>(uri, {headers: headers});
  }
  
  get(id): Observable<VendorsPostPutGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<VendorsPostPutGetId>(uri, {headers: headers});
  }

  delete(id): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

}

interface VendorsGet {
  "content": VendorsPostPutGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface VendorsPostPutGetId {
  // "account": string,
  "active": boolean,
  "address": string,
  "city": string,
  "comment": string,
  "contactName": string,
  "country": string,
  "email": string,
  "fax": string,
  "id"?: number,
  "name": string,
  "phone": string,
  "postalCode": string,
  "province": string,
  "state": string,
  "website": string
}

interface Short {
  id: number,
  name: string
}