import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//Services
import { 
  AuthGuardService as AuthGuard 
} from './services/auth-guard/auth-guard.service';
//Components
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistrationConfirmComponent } from './pages/auth/registration-confirm/registration-confirm.component';
import { PageContainerComponent } from './pages/page-container/page-container.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'registration-confirm', component: RegistrationConfirmComponent },
  { path: 'forgot-password', component: ForgotComponent },
  { path: 'page', canActivate: [AuthGuard], component: PageContainerComponent, children: [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'dashboard', loadChildren: () => import('./pages/modules/dashboard/dashboard.module').then(mod => mod.DashboardModule) },
    { path: 'inventory', loadChildren: () => import('./pages/modules/inventory/inventory.module').then(mod => mod.InventoryModule) },
    { path: 'sales', loadChildren: () => import('./pages/modules/sales/sales.module').then(mod => mod.SalesModule) },
    { path: 'locations', loadChildren: () => import('./pages/modules/locations/locations.module').then(mod => mod.LocationsModule) },
    { path: 'users', loadChildren: () => import('./pages/modules/users/users.module').then(mod => mod.UsersModule) },
    { path: 'settings', loadChildren: () => import('./pages/modules/settings/settings.module').then(mod => mod.SettingsModule) },
    // { path: 'settings', loadChildren: 'src/app/pages/modules/settings/settings.module#SettingsModule' },
    { path: '**', redirectTo: 'dashboard' }
  ] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
