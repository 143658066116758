import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('.1s', style({opacity: '1'}))
      ]),
      transition(':leave', [
        style({opacity: '1'}),
        animate('.1s', style({opacity: '0'}))
      ])
    ])
  ]
})
export class FormSelectComponent implements OnInit {
  @Input() label: string = null;
  @Input() width: string = "200px";
  @Input() control: AbstractControl;
  @Input() options: object[] = [];
  constructor() { }
  isControlInvalid(control): boolean {
    const result = control.invalid && control.touched;
    return result;
  }
  ngOnInit() {
  }

}
