import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettingsService as _appSettings } from './../app-settings/app-settings.service';

@Injectable()
export class AuthService {

	constructor(private _http: HttpClient) { }

	public model = 'auth';

	login(data): Observable<User> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/user/signin';
		return this._http.post<User>(uri, data);
	}

	change(data: ChangePass): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + `/user/changePassword?password=${data.password}&restoreToken=${data.restoreToken}`;
		return this._http.put<any>(uri, {});
	}

	drop(data): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + `/user/dropPassword?emails=${data.email}`;
		return this._http.post<any>(uri, data);
	}

	public isAuthenticated(): boolean {
		const token = localStorage.getItem('token');
		// Check whether the token is expired and return
		// true or false
		return !!token;
	}
	public getToken(): string {
		return localStorage.getItem("token") || "¯＼_(ツ)_/¯ you don't have a token!";
	}
}
interface User {
	accessToken: string,
	user: {
		id: number,
		lastName: string,
		firstName: string,
		email: string,
		description: any,
		addresses: any,
		roles: string[]
	}
}

interface ChangePass {
	password: string,
	restoreToken: string
}