import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from './../../../services';

@Component({
  selector: 'app-registration-confirm',
  templateUrl: './registration-confirm.component.html',
  styleUrls: ['./registration-confirm.component.scss']
})
export class RegistrationConfirmComponent implements OnInit {
  public form: FormGroup;
  router: Router;
  sended = false;
  constructor(
    private formBuilder: FormBuilder,
    _router: Router,
    private _auth: AuthService,
    private activatedRoute: ActivatedRoute
  ){
    this.form = formBuilder.group({
      password: ['', Validators.required],
      password2: ['', Validators.required],
      restoreToken: ['', Validators.required]
    });
    this.router = _router;
  }
  public onSubmit() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls)
      .forEach(controlName => controls[controlName].markAsTouched());
      // Breake run method 
      return;
    }
    let newPassword = {
      password: this.form.value.password,
      restoreToken: this.form.value.restoreToken
    }
    console.log(newPassword);
    this._auth.change(newPassword).subscribe(
      res => {
        this.router.navigate(['login']);
      },
      err => {
        console.error('Error in save new password')
      }
    );
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const restoreToken = params['token'];
      this.form.patchValue({restoreToken: restoreToken});
    });
  }

}
