import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SalesOrdersService {

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public model = 'salesOrders';

  create(data): Observable<SalesOrderIds> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.post<SalesOrderIds>(uri, data, {headers: headers});
  }

  update(data): Observable<SalesOrdersPostPut> {
    const uri = _appSettings.API_ENDPOINT + this.model;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<SalesOrdersPostPut>(uri, data, {headers: headers});
  }

  list(data): Observable<SalesOrdersGet> {
    // check and set params of pagination
    const size = 'size=' + (data.size || '');
    const page = 'page=' + (data.number || '');
    const search = 'salesOrders=' + (data.search || '');
    const params = `?${size}&${page}&${search}`;
    const uri = _appSettings.API_ENDPOINT + this.model + params;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesOrdersGet>(uri, {headers: headers});
  }
  
  get(id): Observable<SalesOrdersGetId> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SalesOrdersGetId>(uri, {headers: headers});
  }

  getShort(id): Observable<SOShort> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/short/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.get<SOShort>(uri, {headers: headers});
  }

  delete(id): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.delete<any>(uri, {headers: headers});
  }

  status(data): Observable<any> {
    const uri = _appSettings.API_ENDPOINT + this.model + '/status/';
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this._auth.getToken())
      .set('accept', '*/*');
    return this._http.put<any>(uri, data, {headers: headers});
  }

}

interface SOShort {
  id: number,
  status: string,
  wp_id: number
}

interface SalesOrderIds {
  sales_order_id: number,
  wp_sales_order_id: number
}

interface SalesOrdersGet {
  "content": SalesOrdersGetId[],
  "empty": boolean,
  "first": boolean,
  "last": boolean,
  "number": number,
  "numberOfElements": number,
  "pageable": {
    "offset": number,
    "pageNumber": number,
    "pageSize": number,
    "paged": boolean,
    "sort": {
      "empty": boolean,
      "sorted": boolean,
      "unsorted": boolean
    },
    "unpaged": boolean
  },
  "size": number,
  "sort": {
    "empty": boolean,
    "sorted": boolean,
    "unsorted": boolean
  },
  "totalElements": number,
  "totalPages": number
}

interface SalesOrdersPostPut {
  "billAddress": string,
  "billCity": string,
  "billCountry": string,
  "billPostalCode": string,
  "billProvince": string,
  "billState": string,
  "closedDate": string,
  "comment": string,
  "confirmedDate": string,
  "customerArchive": string,
  "customerId": number,
  "deliveredDate": string,
  "deliveryCost": number,
  "discount": number,
  "expReturnDate": string,
  "fullfieldDate": string,
  "id"?: number,
  "insurance": number,
  "orderDate": string,
  "period": string,
  "phone": string,
  // "salesOrder": string,
  "shipAddress": string,
  "shipCity": string,
  "shipCountry": string,
  "shipPostalCode": string,
  "shipProvince": string,
  "shipState": string,
  "status": string,
  "tax": number,
  "totalPrice": number,
  "totalQuantity": number,
  "type": string,
  "typePrice": string
}

interface SalesOrdersGetId {
  "billAddress": string,
  "billCity": string,
  "billCountry": string,
  "billPostalCode": string,
  "billProvince": string,
  "billState": string,
  "closedDate": string,
  "comment": string,
  "confirmedDate": string,
  "customer": {
    "firstName": string,
    "id": number,
    "lastName": string,
    "phone": string,
    "username": string
  },
  "customerArchive": string,
  "deliveredDate": string,
  "deliveryCost": number,
  "discount": number,
  "expReturnDate": string,
  "fullfieldDate": string,
  "id": number,
  "wp_id": number,
  "insurance": number,
  "orderDate": string,
  "period": string,
  "phone": string,
  "salesOrder": string,
  "shipAddress": string,
  "shipCity": string,
  "shipCountry": string,
  "shipPostalCode": string,
  "shipProvince": string,
  "shipState": string,
  "status": string,
  "tax": number,
  "totalPrice": number,
  "totalQuantity": number,
  "type": string,
  "typePrice": string
}