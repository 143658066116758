import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-delivery',
  templateUrl: './icon-delivery.component.html',
  styleUrls: ['./icon-delivery.component.scss']
})
export class IconDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
