import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public form: FormGroup;
	router: Router;
	constructor(
		private authService: AuthService,
		private formBuilder: FormBuilder,
		_router: Router
	) {
		this.form = formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});
		this.router = _router;
	}
	onSubmit() {
		const controls = this.form.controls;
		if (this.form.invalid) {
			Object.keys(controls)
				.forEach(controlName => controls[controlName].markAsTouched());
			// Breake run method 
			return;
		} else {
			this.login(this.form.value);
		}
	}

	login(data) {
		console.log(data);
		this.authService.login(data).subscribe(
			res => {
				let token = res.accessToken;
				let user = res.user;
				localStorage.setItem("token", token);
				localStorage.setItem("user", JSON.stringify(user.email));
				//localStorage.setItem("user", "dadwada");
				//["PRODUCTS_ALL", "VENDORS_ALL", "PURCHASE_ORDER_ALL", "PRODUCT_CATEGORIES_ALL", "SALES_ORDERS_ALL", "CUSTOMERS_ALL", "FACILITIES_ALL", "ROOMS_ALL", "SALES_TAX_RATES_ALL", "DISCOUNTS_ALL", "DELIVERY_AND_PICKUP_ALL", "INSURANCE_ALL", "VARIABLES_ALL", "USERS_ALL", "ROLES_ALL", "LOGS_VIEW", "COMPANY_INFO_ALL"]
				localStorage.setItem("roles", JSON.stringify(["PRODUCTS_ALL", "VENDORS_ALL", "PURCHASE_ORDER_ALL", "PRODUCT_CATEGORIES_ALL", "SALES_ORDERS_ALL", "CUSTOMERS_ALL", "FACILITIES_ALL", "ROOMS_ALL", "SALES_TAX_RATES_ALL", "DISCOUNTS_ALL", "DELIVERY_AND_PICKUP_ALL", "INSURANCE_ALL", "VARIABLES_ALL", "USERS_ALL", "ROLES_ALL", "LOGS_VIEW", "COMPANY_INFO_ALL"]));
				//localStorage.setItem("roles", JSON.stringify(user.roles));
				//console.log("fffawczc " + JSON.stringify(user.roles));
				//TODO
				this.router.navigate(['/page']);
			},
			err => {
				this.form.controls['email'].setErrors({ 'incorrect': true });
				this.form.controls['password'].setErrors({ 'incorrect': true });
			}
		);
	}
	logout(): void {
		localStorage.removeItem("token");
		localStorage.removeItem("user");
	}
	ngOnInit() {
		this.logout();
	}

}