import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination-select',
  templateUrl: './pagination-select.component.html',
  styleUrls: ['./pagination-select.component.scss']
})
export class PaginationSelectComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() currentPage: number = 0;
  @Output() change = new EventEmitter();
  constructor() { }
  setPage(page): void {
    this.change.emit(page);
    // this.currentPage = page;
  }
  ngOnInit() {
  }

}
